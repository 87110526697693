import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit  {
  constructor(@Inject(DOCUMENT) private document: Document) {
  }
  ngOnInit() {
    this.document.documentElement.lang = 'vi';
    this.document.documentElement.translate = false;
  }
}
