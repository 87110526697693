export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCP6D36GamZxjCrLWGql4UAv15lICwfOpQ",
    authDomain: "project1-c553b.firebaseapp.com",
    databaseURL: "https://project1-c553b.firebaseio.com",
    projectId: "project1-c553b",
    storageBucket: "project1-c553b.appspot.com",
    messagingSenderId: "572900747624",
    appId: "1:572900747624:web:3a309f932a32b27fc10edd"
  }
};
